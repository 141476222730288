












































































































































































import { Component } from "vue-property-decorator";
import { listMixin } from "@/mixins/listMixin";
import { mixins } from "vue-class-component";
import MainHeader from "@/components/main-header/main-header.vue";
import NavLeft from "@/components/nav-left/nav-left.vue";
import Pagination from "@/components/paginaiton.vue";
import {
  InviteCodeList,
  BanApplyInviteCode,
  ChangeInviteCode,
} from "@/request/manage";
@Component({
  components: {
    MainHeader,
    NavLeft,
    Pagination,
  },
})
export default class Name extends mixins(listMixin) {
  private window: any = window;
  private codes: any[] = [];
  private code: any = {};
  private ifShowChangeCode: boolean = false;
  /**
   * @description 获取邀请码列表
   */
  public getList() {
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    const params: any = {
      current_page: this.filters.current_page,
      page_count: this.filters.page_count,
      sort_value: this.filters.sort_value,
    };
    InviteCodeList(this, params, loading)
      .then((data: any) => {
        loading.close();
        this.codes = data.codes;
        this.filters.total_count = data.total_count;
        this.filters.total_page = data.total_page;
        if (this.codes.length === 0) {
          this.ifShowEmpty = true;
        } else {
          this.ifShowEmpty = false;
        }
      })
      .catch(() => {
        loading.close();
      });
  }
  /**
   * @description 自动计算所属层级
   */
  private calCengji(jigou: any) {
    let jigouText = "";
    jigou["组织机构"].forEach((j: any) => {
      jigouText += j["名称"] + "-";
    });
    jigouText = jigouText.substring(0, jigouText.length - 1);
    return jigouText;
  }
  /**
   * @description 禁用或启用账号
   */
  private banApplyAccount(code: any) {
    const action: any = code["状态"] === "禁用" ? "启用" : "禁用";
    if (action === "禁用") {
      this.$confirm("禁用后无法邀请他人，确定要禁用吗？")
        .then(() => {
          const params: any = {
            code_id: code.code_id,
            action,
          };
          BanApplyInviteCode(this, params).then((data: any) => {
            this.$message.success(action + "成功");
            this.getList();
          });
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      const params: any = {
        code_id: code.code_id,
        action,
      };
      BanApplyInviteCode(this, params).then((data: any) => {
        this.$message.success(action + "成功");
        this.getList();
      });
    }
  }
  /**
   * @description 打开修改邀请码弹窗
   */
  private openChangeInviteCodeDialog(code: any) {
    this.code = code;
    this.ifShowChangeCode = true;
  }
  /**
   * @description 修改邀请码
   */
  private changeInviteCode() {
    if (!this.code["new_code"]) {
      this.$message.warning("新邀请码不能为空");
      return;
    }
    if (!this.code["re_code"]) {
      this.$message.warning("请再次输入邀请码");
      return;
    }
    if (this.code["new_code"] !== this.code["re_code"]) {
      this.$message.warning("两次输入的邀请码不一致，请重新填写");
      return;
    }
    const params: any = {
      code_id: this.code.code_id,
      code: this.code["new_code"],
    };
    ChangeInviteCode(this, params).then((data: any) => {
      this.$message.success("修改成功");
      this.ifShowChangeCode = false;
      this.code = {};
      this.getList();
    });
  }

  /**
   * @description 初始化
   */
  mounted() {
    this.getList();
  }
}
